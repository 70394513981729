import React from 'react';

const Option = ({
  label, value, isSelected, onClick
}) => {
  return (
    <li className={`mdc-list-item ${isSelected ? 'mdc-list-item--selected' : ''}`}>
      <button
        type="button"
        onClick={() => onClick(label, value)}
        data-value={value}
        className="mdc-list-item__btn"
      >
        <span className="mdc-list-item__ripple" />
        <span className="mdc-list-item__text">{label}</span>
      </button>
    </li>
  );
};

export default Option;
