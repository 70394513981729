/* eslint-disable react/button-has-type */
import React from 'react';
import Icon from '../Icons';

const Button = ({
  className, label, handleClick, value, color, disabled, kind, type, icon
}) => {
  const classes = `${className || ''} btn-${kind} btn-${kind}--${color} ${disabled ? 'btn--disabled' : ''} ${icon ? 'btn-secondary--with-icon' : ''}`;
  const buttonType = type || 'button';
  return (
    <button
      type={buttonType}
      disabled={disabled}
      onClick={() => handleClick(value)}
      className={classes}
    >
      <span className="btn__label">
        {label}
      </span>
      {
        icon && <Icon className="btn__icon icon--24" path={icon} />
      }
    </button>
  );
};

Button.defaultProps = {
  handleClick: () => { }
};

export default Button;
